// Generated by Framer (9f2fb1c)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, RichText, SmartComponentScopedContainer, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import TableOfContents from "../codeFile/Embedding/TableOfContents.tsx";
import * as sharedStyle from "../css/inwSkcFXB";
import NavLink from "./g8h7Qo5MM";
const NavLinkFonts = getFonts(NavLink);
const TableOfContentsFonts = getFonts(TableOfContents);

const serializationHash = "framer-54QwW"

const variantClassNames = {l6K4XfbPT: "framer-v-huwy1m"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({content, defaultPath, height, id, slug, title, width, ...props}) => { return {...props, LZXT065jA: defaultPath ?? props.LZXT065jA ?? "blog", M38UDbA06: content ?? props.M38UDbA06 ?? <React.Fragment><motion.p>Content</motion.p></React.Fragment>, pyQp2DkPY: slug ?? props.pyQp2DkPY ?? "customer-engagement-ziele-arten-und-kpis-im-überblick", vyK9t0pJV: title ?? props.vyK9t0pJV ?? "Inhaltsverzeichnis"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;content?: string;slug?: string;title?: string;defaultPath?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, M38UDbA06, pyQp2DkPY, vyK9t0pJV, LZXT065jA, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "l6K4XfbPT", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = [sharedStyle.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.nav {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-huwy1m", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"l6K4XfbPT"} ref={refBinding} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1tnbp25"} data-styles-preset={"inwSkcFXB"}>Inhaltsverzeichnis</motion.p></React.Fragment>} className={"framer-14eyf7j"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"y0MduCEhY"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={vyK9t0pJV} verticalAlignment={"top"} withExternalLayout/><ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-l29dw6-container"} isAuthoredByUser layoutDependency={layoutDependency} layoutId={"fojrC9d_u-container"} nodeId={"fojrC9d_u"} rendersWithMotion scopeId={"cClcm885L"}><TableOfContents description={M38UDbA06} heading={[<ComponentViewportProvider height={31}><SmartComponentScopedContainer className={"framer-13bpq4w-container"} data-framer-name={"Nav Link 1"} inComponentSlot layoutDependency={layoutDependency} layoutId={"b4V_O7bck-container"} name={"Nav Link 1"} nodeId={"b4V_O7bck"} rendersWithMotion scopeId={"cClcm885L"}><NavLink height={"100%"} id={"b4V_O7bck"} layoutId={"b4V_O7bck"} name={"Nav Link 1"} variant={"fNA7yE2uP"} width={"100%"} YeCLcIG7G={"Getting started"}/></SmartComponentScopedContainer></ComponentViewportProvider>]} height={"100%"} id={"fojrC9d_u"} layoutId={"fojrC9d_u"} path={LZXT065jA} slug={pyQp2DkPY} style={{width: "100%"}} width={"100%"}/></SmartComponentScopedContainer></ComponentViewportProvider></motion.nav></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-54QwW.framer-jlahdo, .framer-54QwW .framer-jlahdo { display: block; }", ".framer-54QwW.framer-huwy1m { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 274px; }", ".framer-54QwW .framer-14eyf7j { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", ".framer-54QwW .framer-l29dw6-container { flex: none; height: auto; position: relative; width: 100%; }", ".framer-54QwW .framer-13bpq4w-container { height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-54QwW.framer-huwy1m { gap: 0px; } .framer-54QwW.framer-huwy1m > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-54QwW.framer-huwy1m > :first-child { margin-top: 0px; } .framer-54QwW.framer-huwy1m > :last-child { margin-bottom: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 53
 * @framerIntrinsicWidth 274
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"M38UDbA06":"content","pyQp2DkPY":"slug","vyK9t0pJV":"title","LZXT065jA":"defaultPath"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 * @framerColorSyntax true
 */
const FramercClcm885L: React.ComponentType<Props> = withCSS(Component, css, "framer-54QwW") as typeof Component;
export default FramercClcm885L;

FramercClcm885L.displayName = "ToCNav";

FramercClcm885L.defaultProps = {height: 53, width: 274};

addPropertyControls(FramercClcm885L, {M38UDbA06: {defaultValue: "<p>Content</p>", title: "Content", type: ControlType.RichText}, pyQp2DkPY: {defaultValue: "customer-engagement-ziele-arten-und-kpis-im-überblick", title: "Slug", type: ControlType.String}, vyK9t0pJV: {defaultValue: "Inhaltsverzeichnis", displayTextArea: false, title: "Title", type: ControlType.String}, LZXT065jA: {defaultValue: "blog", title: "Default Path", type: ControlType.String}} as any)

addFonts(FramercClcm885L, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...NavLinkFonts, ...TableOfContentsFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})